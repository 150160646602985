<template>
    <layout>
        <page title="History List">
            <template v-slot:header-content>
                <div class="form-group form-inline mr-10">
                    <label class="mt-5">FROM </label>
                    <input v-model="form.from_date" @change="getList()" style="width: 250px;" type="date" class="form-control" name="from_date" id="from_date">
                </div>
                <div class="form-group form-inline mr-20">
                    <label class="mt-5">TO </label>
                    <input v-model="form.to_date" @change="getList()" style="width: 250px;" type="date" class="form-control" name="to_date" id="to_date">
                </div>
                <input type="text" id="search" name="search" v-model="form.search" @keyup="getList()" class="form-control mr-10" placeholder="Search">
                <router-link to="/inventory" class="btn btn-default mr-5"><i class="fa fa-ban"></i> Back</router-link>
            </template>
            <table class="table table-hover table-outer-bordered font-size-12">
                <thead>
                    <tr>
                        <th width="10%" class="text-center">🗓 DATE</th>
                        <th width="8%" class="text-center">⚒ TYPE</th>
                        <th width="20%" class="text-center">🗄 ITEM</th>
                        <th width="10%" class="text-center">♟️ QTY</th>
                        <th width="20%" class="text-center">👤 EMPLOYEE</th>
                        <th width="40%" class="text-center">📜 REMARKS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in data.data" :key="item.id">
                        <td class="text-center">{{ item.transaction_date }}</td>
                        <td class="text-center">{{ item.type }}</td>
                        <td class="text-center">
                            {{ item.name }}
                            <div class="text-muted" style="font-size: 1.1rem;">
                                <span v-if="item.size" class="text-success font-weight-semi-bold">({{ item.size }})</span>
                            </div>
                        </td>
                        <td class="text-center">{{ item.quantity }}</td>
                        <td class="text-center">{{ item.initial }}</td>
                        <td class="text-center">{{ item.remarks }}</td>
                    </tr>
                </tbody>
            </table>
            <template v-slot:pagination>
                <pagination
                    v-if="data.links && data.links.length > 3"
                    :links="data.links"
                    :first_page_url="data.first_page_url"
                    :last_page_url="data.last_page_url"
                    :prev_page_url="data.prev_page_url"
                    :next_page_url="data.next_page_url"
                    v-on:click="getList"
                ></pagination>
            </template>
        </page>
    </layout>
</template>

<script>
import Page from "../../components/Page";
import Pagination from "../../components/Pagination";
import * as dayjs from "dayjs";

export default {
    components: {
        Page,
        Pagination
    },
    data() {
        return {
            data: [],
            search: '',
            form: {
                from_date: dayjs().startOf('month').format('YYYY-MM-DD'),
                to_date: dayjs().endOf('month').format('YYYY-MM-DD'),
                search: '',
            },
        }
    },
    created() {
        this.getList();
    },
    methods: {

        getList(link = null) {
            let newLink = link || `/api/adjustments/getHistory`;
            this.$http.post(`${newLink}`, this.form).then((response) => {
                this.data = response.data;
            });
        }
    }
}
</script>
