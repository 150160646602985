<template>
    <div>
        <div class="d-flex justify-content-between">
            <h5>{{ title }}</h5>
            <div class="mt-15 d-flex justify-content-between">
                <slot name="header-content"></slot>
            </div>
        </div>
        <div>
            <slot></slot>
            <div class="d-flex justify-content-end">
                <slot name="pagination"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page',
    props: [ 'title' ]
}
</script>